<script lang="ts">
  import axios from 'axios'
  import { onMount } from 'svelte'
  import type { ArticleType, LangType, VideoType } from '@customTypes/newscraft'
  import kiniTVlogo from '@assets/logo/kinitv_black.svg'
  import { useDateTimeTranslations, useTranslations } from '@i18n/utils'
  import { Radio } from 'flowbite-svelte'
  import { imageLoader } from '@utils/imageLoader'

  export let lang: LangType
  export let isMobile: boolean

  const kiniTVUrl = {
    en: 'https://www.kinitv.com/en',
    zh: 'https://www.kinitv.com/cn',
    ms: 'https://www.kinitv.com/'
  }

  const t = useTranslations(lang)

  let data: VideoType[] = []
  let selectedId: string = ''
  let playList: VideoType[] = []

  onMount(async () => {
    const res = await axios.post('/api/getVideos', { lang, limit: 4 })

    data = res.data.videos
    selectedId = data[0].videoId
  })

  const dt = useDateTimeTranslations(lang)

  $: topVideo = data && data.find(v => v.videoId === selectedId)

  $: playList = data && data.filter(v => v.videoId !== selectedId)
</script>

<div class={`${isMobile ? 'border-t-2 border-brandBlue py-4' : 'mt-14 rounded-xl bg-orange-50 px-8 py-8'}`}>
  <h2 class="sr-only">KiniTV</h2>
  <img
    src={kiniTVlogo.src}
    alt=""
    class="mb-6 h-8"
    loading="lazy"
  />

  <div class={`container flex gap-x-20 ${isMobile ? 'flex-col' : 'flex-row'}`}>
    {#if topVideo}
      <div class={`${isMobile ? '' : 'basis-1/2'}`}>
        <iframe
          src={topVideo.videoLink}
          frameborder={0}
          title={topVideo.title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          class="aspect-video w-full rounded-lg"
          loading="lazy"
        />
        <div class="my-3 px-4 text-slate-900">
          <h3 class="text-lg font-semibold">{topVideo.title}</h3>
          <time
            class="my-1 text-sm text-slate-600"
            datetime={dt({ datetime: topVideo.date_pub })}>{dt({ datetime: topVideo.date_pub, relative: true })}</time
          >
        </div>
      </div>
    {/if}

    <div class={`flex flex-col gap-y-6  ${isMobile ? 'my-6' : 'my-0 basis-1/2'}`}>
      {#each playList as v (v.videoId)}
        <Radio
          class={`group flex w-full flex-row gap-x-3 rounded-lg p-3 text-left shadow duration-300 ease-in-out hover:cursor-pointer hover:shadow-md hover:transition-all ${
            isMobile ? 'border border-brandOrange' : 'border-0 bg-white'
          }`}
          value={v.videoId}
          custom
          on:change={() => (selectedId = v.videoId)}
        >
          <img
            src={imageLoader(v.image_feat_single, 150)}
            alt=""
            class="aspect-[4.5/3] h-20 w-32 flex-none overflow-hidden rounded"
            loading="lazy"
            role="presentation"
          />
          <div class="flex flex-col justify-between self-stretch">
            <h3
              class="text-base font-semibold text-slate-900 underline-offset-4 duration-300 group-hover:text-brandOrange group-hover:underline"
            >
              {v.title}
            </h3>
            <time
              class="my-1 text-sm text-slate-600"
              datetime={dt({ datetime: v.date_pub })}
            >
              {dt({ datetime: v.date_pub, relative: true })}
            </time>
          </div>
        </Radio>
      {/each}
      <a
        href={kiniTVUrl[lang]}
        class="hover mx-auto flex w-full items-center justify-center rounded-lg border bg-brandOrange p-5 text-center font-semibold text-white duration-300 hover:shadow-lg"
        >{t('video.more')}
      </a>
    </div>
  </div>
</div>
